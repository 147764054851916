import React, {useEffect, useRef, useState} from 'react';
import store from "../store";
import {showToastMessage} from "../core/actions/appActions";
import "../assets/css/contactUs.css";
import {contactUs} from './ContactUsAction';
import Footer from "../core/layout/footer/footer";
import ReCAPTCHA from 'react-google-recaptcha';
import CitySection from "./subContactUs/CitySection";
import {isEmpty} from "../utils/validations";
import Loader from "../core/components/Loader/loader";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function ContactUsView(props) {
    const recaptchaRef = useRef(null);
    const field1Ref = useRef(null);
    const field2Ref = useRef(null);
    const field3Ref = useRef(null);
    const field4Ref = useRef(null);
    const field5Ref = useRef(null);

    const {isMobileView, isXSView} = props;
    const [captchaValue, setCaptchaValue] = useState(null);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [formValues, setFormValues] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        comments: '',
    });
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        comments: '',
        recaptcha: '',
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props} />
        );
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        if (name === 'comments' && value.length > 1000) {
            setErrors((prevValues) => ({
                ...prevValues,
                [name]: 'Maximum 1000 characters allowed',
            }));
        } else {
            setErrors((prevValues) => ({
                ...prevValues,
                [name]: '',
            }));
        }
    };

    const validateName = (name) => {
        const re = /^[a-zA-Z\s]*$/;
        return re.test(name);
    };

    const validateEmail = (email) => {
        const localEmail = email?.trim();
        if (!localEmail || localEmail.includes(" ")) {
            return false;
        }

        if (!/^[a-zA-Z\d@._-]+$/.test(localEmail)) {
            return false;
        }

        const parts = localEmail.split('@');
        if (parts.length !== 2 || isEmpty(parts[0]) || isEmpty(parts[1])) {
            return false;
        }

        if (!parts[1].includes('.') || parts[1].startsWith('.') || parts[1].endsWith('.')) {
            return false;
        }

        return true;
    };


    const handleBlur = (e) => {
        const {name, value} = e.target;

        let error = '';

        if (name === 'first_name') {
            if (!value.trim()) error = 'Please enter First name';
            else if (!validateName(value)) error = 'Must be filled by a~z, A~Z';
        }
        if (name === 'last_name') {
            if (!value.trim()) error = 'Please enter Last name';
            else if (!validateName(value)) error = 'Must be filled by a~z, A~Z';
        }
        if (name === 'phone_number') {
            if (!value.trim()) error = 'Please enter Phone number';
            else if (value.trim().length < 10) error = 'Number must be at least 10 characters long';
        }
        if (name === 'email') {
            if (!value.trim()) {
                error = 'Please enter Email';
            } else if (name === 'email' && !validateEmail(value)) {
                error = 'Please enter valid Email';
            }
        }
        if (name === 'comments') {
            if (!value.trim()) error = 'Please enter Message';
            if(value.length > 1000) {
                error = 'Maximum 1000 characters allowed';
            }
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            recaptcha: ''
        }));
    };

    const renderLoader = () => {
        return (<Loader type={"screen"} isVisible={isShowLoader}/>);
    };


    const handlerSubmit = (event) => {
        event.preventDefault();
        let first_name = event.target.first_name.value;
        let last_name = event.target.last_name.value;
        let email = event.target.email.value;
        let phoneNumber = event.target.phone_number.value;
        let website = '';
        let description = event.target.comments.value;
        let name = first_name + " " + last_name;

        const newErrors = {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            comments: '',
            recaptcha: '',
        };

        if (!first_name) {
            newErrors.first_name = 'Please enter First name';
        } else if (!validateName(first_name)) {
            newErrors.first_name = 'Must be filled by a~z, A~Z';
        }
        if (!last_name) {
            newErrors.last_name = 'Please enter Last name';
        } else if (!validateName(last_name)) {
            newErrors.last_name = 'Must be filled by a~z, A~Z';
        }
        if (email === '') {
            newErrors.email = 'Please enter Email';
        } else if (!validateEmail(email)) {
            newErrors.email = 'Please enter valid Email';
        }
        if (phoneNumber === '') {
            newErrors.phone_number = 'Please enter Phone number';
        } else if (phoneNumber.length < 10) {
            newErrors.phone_number = 'Number must be at least 10 characters long';
        }
        if (!description) {
            newErrors.comments = 'Please enter Message';
        } else if(description.length > 1000) {
            newErrors.comments = 'Maximum 1000 characters allowed';
        }
        if (!captchaValue) {
            newErrors.recaptcha = 'Captcha is required';
        }
        setErrors(newErrors);

        if (newErrors.first_name && field1Ref.current) field1Ref.current.focus();
        else if (newErrors.last_name && field2Ref.current) field2Ref.current.focus();
        else if (newErrors.email && field3Ref.current) field3Ref.current.focus();
        else if (newErrors.phone_number && field4Ref.current) field4Ref.current.focus();
        else if (newErrors.comments && field5Ref.current) field5Ref.current.focus();

        let payload = {
            name,
            email,
            phoneNumber,
            website,
            description
        };

        if (Object.values(newErrors).every((error) => error === '')) {
            setIsShowLoader(true);
            contactUs(payload).then(response => {
                if (response.status === 204 || response.status === 201 || response.status === 200) {
                    setIsShowLoader(false);
                    store.dispatch(showToastMessage('success', "Thank you for contacting us."));
                    setFormValues({
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone_number: '',
                        comments: '',
                    });
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }
                } else {
                    setIsShowLoader(false);
                }
            });
        }
    };

    return (
        <div>
            {isMobileView ?
                <div className="container-x">
                    <div className="d-flex align-items-center  back-arrow">
                        <i className="fa-solid fa-arrow-left me-2" onClick={() => {
                            props.history.goBack()
                        }}/>
                        <div className="py-3" onClick={() => {
                            props.history.goBack()
                        }}>Back
                        </div>
                    </div>
                    <div className=" mt-4">
                        <div className="page-title m-0">Contact Us</div>
                    </div>
                </div>
                :
                <div className="topsection"/>
            }
            <section className="coffffn">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6 col-12 order-lg-0 order-1">
                            <div style={isMobileView ? {marginTop: '45px'} : {}}>
                                <div className='page-title'>Get in Touch</div>
                            </div>
                            <form onSubmit={handlerSubmit} id="submit_form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="first_name"
                                               ref={field1Ref}
                                               className={errors.first_name ? 'border-danger' : ''}
                                               placeholder="First Name"
                                               onBlur={handleBlur}
                                               onChange={handleChange}
                                               value={formValues.first_name}/>
                                        {errors.first_name &&
                                        <span className="error-text text-danger">{errors.first_name}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="last_name"
                                               ref={field2Ref}
                                               placeholder="Last Name"
                                               className={errors.last_name ? 'border-danger' : ''}
                                               onBlur={handleBlur}
                                               onChange={handleChange}
                                               value={formValues.last_name}/>
                                        {errors.last_name &&
                                        <span className="error-text text-danger">{errors.last_name}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="email"
                                               ref={field3Ref}
                                               placeholder="Email"
                                               className={errors.email ? 'border-danger' : ''}
                                               onBlur={handleBlur}
                                               onChange={handleChange}
                                               value={formValues.email}/>
                                        {errors.email && <span className="error-text text-danger">{errors.email}</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" id="phone_number" name="phone_number"
                                               ref={field4Ref}
                                               placeholder="Phone Number"
                                               className={errors.phone_number ? 'border-danger' : ''}
                                               onBlur={handleBlur}
                                               onChange={handleChange}
                                               value={formValues.phone_number}/>
                                        {errors.phone_number &&
                                        <span className=" error-text text-danger">{errors.phone_number}</span>}
                                    </div>
                                </div>
                                <div className="d-flex flex-column mb-md-5"
                                     style={isMobileView ? {marginBottom: '20px'} : {gap: 0}}>
                                            <textarea rows="6"
                                                      placeholder="Type a Message"
                                                      name="comments"
                                                      ref={field5Ref}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      className={`form-control-sm ${errors.comments ? 'border-danger' : ''}`}
                                                      value={formValues.comments}
                                                      maxLength="1001"
                                            >
                                            </textarea>
                                    {errors.comments &&
                                    <span className="error-text text-danger">{errors.comments}</span>}
                                </div>
                                <div>
                                    <ReCAPTCHA
                                        name="recaptcha"
                                        ref={recaptchaRef}
                                        sitekey={RECAPTCHA_SITE_KEY}
                                        onChange={handleCaptchaChange}
                                    />
                                    {errors.recaptcha &&
                                    <span className="error-text text-danger">{errors.recaptcha}</span>}
                                </div>
                                {!isMobileView &&
                                <button type="submit"
                                        className="btn btn-outline-dark btn-lg ms-0 w-50 mt-5 section-header ">
                                    SEND A MESSAGE
                                </button>
                                }
                                <button type="submit"
                                        className=" btn btn-outline-dark btn-lg ms-0" hidden>
                                    SEND A MESSAGE
                                </button>
                            </form>
                        </div>

                        <div className="col-lg-6 col-12 order-lg-1 order-0">
                            <div className="connectCity ms-0 ms-lg-5 ">
                                <CitySection/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isMobileView &&
            <div className="submit-btn-shadow mt-5 container-x">
                <button id="send-msg-btn" type="submit" className=" btn-outline-dark btn btn-lg my-3 w-100"
                        form='submit_form'>
                    SEND A MESSAGE
                </button>
            </div>}
            {!isMobileView && renderFooter()}
            {renderLoader()}
        </div>
    );
}

ContactUsView.propTypes = {};

export default ContactUsView;
